jQuery(document).ready(function($) {

	//Tidy up Admin Menu

	// Remove pesky title attribute from our repurposed WP Logo menu item.
	// $('#wp-admin-bar-wp-logo > a').removeAttr('title');

	// Shorten any site names that we can...
	$('#wp-admin-bar-my-sites-list').children('li').each(function() {
		$(this).children('a').each(function(){
			var text = $(this).text();
			if ( text !== 'City of Sanctuary' ) {
				text = text.replace('City ', '').replace('Town ', '').replace('Stream ', '').replace('of Sanctuary', '');
			} else {
				text = 'National';
			}
			$(this).text(text).prepend('<div class="blavatar"></div> ');
		});
	});

});