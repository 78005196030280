jQuery( document ).ready( function( $ ) {

	// When this is checked: #_cofs_enable_hero
	// Show/hide this: #cofs_hero_meta

	if ( $( '#_cofs_enable_hero' ).prop('checked') ) {
		$( '#cofs_hero_meta' ).css( 'display', 'block' ).addClass( 'show-fancy-header' );
	}

	$( '#_cofs_enable_hero' ).change( function() {

		if ( $( '#_cofs_enable_hero' ).prop( 'checked' ) ) {
			$( '#cofs_hero_meta' ).addClass( 'show-fancy-header' ).css( 'display', 'block' );
		} else {
			$( '#cofs_hero_meta' ).removeClass( 'show-fancy-header' ).css( 'display', 'none' );
		}

	} );

} );